
























































































































































































































































































































































































































































































.active { 
    color:#D200D2; 
    text-decoration:none; 
} 
.oneMenu{
    line-height:30px;
    text-align:left;
    padding-left:5px;
    font-size:10px;
    color:white;
    background-color: #409eff;
}
.twoMenu{
    display:flex;
    flex-direction:column;
    line-height:25px;
    background:white;
    padding-left:10px;

}
.twoMenu:hover{
    cursor: pointer;
    color:#409EFF;
}
.oneMenu:hover{
    cursor: pointer;
    color:#f4f6f8;
}
.el-cascader-menu{
    height:400px;
    width:100%;
}
.item-width  {
    width: 48%;
    color:#409EFF;
}
.video-js .vjs-icon-placeholder {
    width: 80%;
    height: 80%;
    display: block;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
